import React, { Component } from 'react';

class TheaterAwardHistoryModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
      logData: null,
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      open: true
    });
    this.loadData();
  }

  closeModal() {
    this.setState({
      open: false
    })
  }

  loadData() {
    const { logData } = this.state;
    console.log("Loading Data");
    fetch(`/engraving/theater-awards/${this.props.item.id}/history`, {
      method: "get",
      redirect: "manual",
      headers: {
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.setState({ logData: json.logs });
      } else {
        alert(json.error);
      }
    });
  }

  renderModal(){
    const { open } = this.state;
    if (open) {
      return (
        <div className="custom-modal" style={{textAlign:"left"}}>
          <div className="backdrop" onClick={this.closeModal}>
          </div>

          <div className="window history-modal" onClick={(e)=> e.stopPropagation()} style={{height:"auto"}}>
            <div className="form-header">
              <h3> Award History </h3>
            </div>
            <br />
            {this.renderHistory()}
          </div>
        </div>
      )
    }
  }

  renderHistory() {
    const { item } = this.props;
    const { logData } = this.state;
    let logs = [
      {
        created_at: item.created_at,
        email: null,
        id: 1,
        message: "Item Created",
        theater_award_trophy_order_id: item.id,
        updated_at: item.created_at,
        user_id: null
      }
    ];
    if(logData) {
      logs = logs.concat(logData.sort((a,b)=>{
        const sA = new Date(a.created_at);
        const sB = new Date(b.created_at);
        return sB < sA ? 1 : -1
      }) );
    }
    return logs.reverse().map(log => (
      <div className="history-modal-log-entry">
        <h4> Changes made on {log.created_at} </h4>
        <div className="row">
          <div className="col-xs-12">
            <b> Update by: </b>
            <br />
            {log.user ? (`${log.user.email}(${log.user.id})`) : "User not found"}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <b> Description: </b>
            <br />
            {log.changeset ? this.renderChangeSet(log) : log.message || "No Message Available"}
          </div>
        </div>
      </div>
    ))
  }

  renderChangeSet(log) {
    let changes = [];
    const filteredKeys=[
      "updated_at"
    ]
    for(let key in log.changeset) {
      if(filteredKeys.indexOf(key) != -1) {

      }else {
        const change = log.changeset[key];
        const oldValue = change[0];
        const newValue = change[1];
        const renderedChange = (
          <div>
          <b>{this.titleize(key)}</b>
          {oldValue != null ? (
            <> changed from <u>{`${oldValue}`}</u> to </>
          ):" set to "}
          <u>{`${newValue}`}</u>
          </div>
        )
        changes=changes.concat([renderedChange]);
      }
    }
    return changes;
  }
  titleize(toTitilize){
      var words = toTitilize.replace(/_/g, ' ').split(' ')
      var array = []
      for (var i = 0; i < words.length; ++i) {
          array.push(words[i].charAt(0).toUpperCase() + words[i].toLowerCase().slice(1))
      }
      return array.join(' ');
  }

  render(){
    console.log({state: this.state, props: this.props});
    return(
      <React.Fragment>
        <a href="javascript:;" onClick={this.openModal}><label>Click here for item history</label></a>
        {this.renderModal()}
      </React.Fragment>
    );
  }
}

export default TheaterAwardHistoryModal;
