/* eslint-disable camelcase */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, pipe } from 'lodash/fp';
import { map, pick, mapKeys } from 'lodash';
import ApiFetcher from '@components/Dashboard/ApiFetcher';
import VisibilityToggler from '@components/Dashboard/VisibilityToggler';
import SectionHeader from '@components/Dashboard/SectionHeader';
import TitleWithCount from '@components/Dashboard/TitleWithCount';
import Row from '@components/Global/Row';
import Col from '@components/Global/Col';
import AwardsPanel from '@components/Panels/AwardsPanel';

class GlobalAwards extends Component {
  static propTypes = {
    toggleVisibility: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      global_awards_engraving_status_report: PropTypes.shape({
        approved: PropTypes.number.isRequired,
        missing_winner: PropTypes.number.isRequired,
        proof_pending: PropTypes.number.isRequired,
        approval_pending: PropTypes.number.isRequired
      }).isRequired,
      global_awards_proof_url: PropTypes.string.isRequired
    }).isRequired
  }

  get options(){
    return pipe(
      (report)=> pick(report, [ "missing_winner", "proof_pending", "approval_pending" ]),
      (statuses)=> mapKeys(statuses, (v, key) => key.replace('_', ' ')),
      (statuses)=> map(statuses, (value, label)=> ({label, value}) )
    )(this.report);
  }

  get report(){
    return this.props.data.global_awards_engraving_status_report;
  }

  get url(){
    return this.props.data.global_awards_proof_url;
  }

  render() {
    const className = classNames(
      {
        'hide': !this.props.isVisible
      }
    );
    var totalAwards = Object.values(this.props.data.global_awards_engraving_status_report).reduce((a, b) => a + b);
    var approved = this.props.data.global_awards_engraving_status_report.approved
    return (
      <section>
        <SectionHeader
          title={()=> (
            <TitleWithCount
              title="Global Awards"
              count={totalAwards+" Total Awards - "+approved+" Approved"}
            />
          )}
          onClick={this.props.toggleVisibility}
          isVisible={this.props.isVisible}
        />
        <Row className={className}>
          <Col col-lg-4 col-sm-12>
            <AwardsPanel
              href={this.url}
              options={this.options}
              title="Global Awards Proof"
              total={this.report.approved}
              bigTotal={this.report.approved+this.report.approval_pending+this.report.missing_winner+this.report.proof_pending}
              totalLabel='approved'
            />
          </Col>
        </Row>
      </section>
    );
  }
}

const enhance = compose(
  VisibilityToggler,
  ApiFetcher
);

export default enhance(GlobalAwards);