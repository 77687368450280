import React from 'react';
import PropTypes from 'prop-types';
import styles from './Hamburger.module.scss';

const Hamburger = ({
  onClick
}) =>{
  return (
    <button type="button" onClick={onClick} className={styles.Hamburger}>
      <i className="fa fa-bars fa-lg" />
    </button>
  );
};

Hamburger.defaultProps = {
  onClick: ()=>{}
};

Hamburger.propTypes = {
  onClick: PropTypes.func
};


export default Hamburger;