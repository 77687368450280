import React from 'react';

const Button = (props) => {
  return (
    <button
    className={props.type}
    onClick={(e)=>{props.handleClick()}}
    disabled={props.disabled}>
      {props.label}
    </button>
  );
}

export default Button;