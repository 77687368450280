import React from 'react';
import PropTypes from 'prop-types';
import { map, reduce } from 'lodash';
import { CircularProgressbar } from 'react-circular-progressbar';
import classNames from 'classnames';
import Row from '@components/Global/Row';
import PanelCriterion from './PanelCriterion';
import styles from './AwardsPanel.module.scss';

const AwardsPanel = ({
  href,
  options,
  title,
  bigTotal,
  total,
  totalLabel
}) => {
  const unapprovedCount = reduce(options, (sum, option) => sum + option.value, 0);
  const percentComplete = 100 - (unapprovedCount / (unapprovedCount + total) * 100);
  return (
    <a className={`award-panel ${styles.AwardsPanel}`} href={href}>
      <span className="top-panel">
        <h3>
          {title}
          <span className="award-count">
            &nbsp;({bigTotal || total})
          </span>
        </h3>
        <span className={styles.svgContainer}>
          <span className="total-label">
            {totalLabel}
          </span>
          <CircularProgressbar
            value={percentComplete}
            text={`${total}`}
            strokeWidth={20}
            classForPercentage={()=>classNames({ complete: percentComplete === 100 })}
          />
        </span>
      </span>
      <Row el="span" className="bottom-panel">
        {
          map( options, (option) => (
            <PanelCriterion
              key={option.label}
              size={12 / options.length}
              {...option}
            />
          ) )
        }
      </Row>
    </a>
  );
};

AwardsPanel.defaultProps = {
  total: 0,
  totalLabel: 'approved'
};

AwardsPanel.propTypes = {
  total: PropTypes.number,
  bigTotal: PropTypes.number,
  totalLabel: PropTypes.string,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
}

export default AwardsPanel;
