import React, { Component } from 'react';
import Button from './Button';
import AwardPanel from './AwardPanel';
import GlobalAwardPanel from './GlobalAwardPanel';
import Accordion from './Accordion';
import Modal from './Modal';
import EngraverSection from './EngraverSection';
import EngraverPanel from './EngraverPanel';
import ReportList from './ReportList';
import UserManagement from './UserManagement';

class App extends Component {
  constructor(props){
    super(props);
    this.placeholderAlert = this.placeholderAlert.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.state = {
      categories: [
        {
          name: "EMEA: Africa",
          total: 10,
          missing: 2,
          pending: 2,
          proof: 2
        },
        {
          name: "Americas: US: East",
          total: 12,
          missing: 1,
          pending: 0,
          proof: 3
        },
        {
          name: "APJ: India",
          total: 8,
          missing: 0,
          pending: 0,
          proof: 0
        }
      ],
      modalActive: false,
      modalContentType: ""
    }
  }

  placeholderAlert(){
    var newCategories = [];
    this.state.categories.forEach(function(element, index){
      newCategories[index] = {};
      newCategories[index].name = element.name;
      newCategories[index].total = Math.floor(Math.random()*100);
      newCategories[index].missing = Math.floor((Math.random()*newCategories[index].total)/3);
      newCategories[index].pending = Math.floor((Math.random()*newCategories[index].total)/3);
      newCategories[index].proof = Math.floor((Math.random()*newCategories[index].total)/3);

    });
    this.setState({categories: newCategories});
  }

  toggleModal(contentType){
    this.setState(function(prevState){
      if(!contentType){
        return({modalActive: !prevState.modalActive, modalContentType: prevState.modalContentType});
      }
      return({modalActive: !prevState.modalActive, modalContentType: contentType});
    });
  }

  render() {
    return (
      <div className="page-content">
        <Modal
          active={this.state.modalActive}
          toggleModal={this.toggleModal}
          contentType={this.state.modalContentType}
        />
        <div className="container">
          <div className="header">
            <Button
              handleClick={this.placeholderAlert}
              type="primary"
              label="Primary Button"
            />
            <Button
              handleClick={()=>{this.toggleModal("sample")}}
              type="default"
              label="Default Button"
            />
          <h1>Partner Summit Digtital Awards Tool</h1>
          </div>
          <Accordion title="Global Awards">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <GlobalAwardPanel
                  title={"Global Partner of the Year Award"}
                  linkPath="#"
                  nomination={true}
                  winner={false}
                  letter={true}
                  logo={true}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <GlobalAwardPanel
                  title={"Enterprise Partner of the Year Award"}
                  linkPath="#"
                  nomination={false}
                  winner={false}
                  letter={true}
                  logo={false}
                />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <GlobalAwardPanel
                  title={"Americas Partner of the Year Award"}
                  linkPath="#"
                  nomination={false}
                  winner={true}
                  letter={false}
                  logo={false}
                />
              </div>
            </div>
          </Accordion>
          <Accordion title="Award Stuff" count={this.state.categories.length}>
            <div className="row">
              {this.state.categories.map(function(r, index){
                return(
                  <div className="col-xs-12 col-sm-6 col-md-4" key={index}>
                    <AwardPanel
                      title={r.name}
                      linkPath="#"
                      total={r.total}
                      missing={r.missing}
                      pending={r.pending}
                      proof={r.proof}
                    />
                  </div>
                );
              })}
            </div>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default App;
