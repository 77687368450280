import React from 'react';

const EngraverSection = (props) => {
  return(
    <div className="engraver-section">
      <div className="engraver-header">
        {props.title}
        <aside>{props.count ? (" ("+props.count+")") : ""}</aside>
      </div>
      {props.children}
    </div>
  );
}

export default EngraverSection;