import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import classNames from 'classnames';

const getClassName = (isIncomplete)=> classNames(
  'unapproved-count',
  {
    incomplete: isIncomplete
  }
)

const AwardPanel = ({
  missing,
  pending,
  proof,
  total,
  bigTotal,
  linkPath,
  title
}) => {
  const unapprovedCount = missing + pending + proof;
  const percentComplete = 100-((unapprovedCount/total)*100);
  return (
    <a className="award-panel" href={linkPath}>
      <div className="top-panel">
        <h3>
          {title}
          <span className="award-count">({bigTotal})</span>
        </h3>
        <div className="total-label">approved</div>
        <CircularProgressbar
          value={percentComplete}
          text={`${total-unapprovedCount}`}
          strokeWidth={20}
          classForPercentage={()=>classNames({ complete: percentComplete === 100 })}
        />
      </div>
      <div className="bottom-panel">
        <div className="third">
          <div className={getClassName(missing > 0)}>
            {missing}
          </div>
          <div className="label">
            missing winners
          </div>
        </div>
        <div className="third">
          <div className={getClassName(pending > 0)}>
            {pending}
          </div>
          <div className="label">
            proofs pending
          </div>
        </div>
        <div className="third">
          <div className={getClassName(proof > 0)}>
            {proof}
          </div>
          <div className="label">
            on proof
          </div>
        </div>
      </div>
    </a>
  );
}

AwardPanel.propTypes = {
  missing: PropTypes.number.isRequired,
  pending: PropTypes.number.isRequired,
  proof: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  bigTotal: PropTypes.number,
  linkPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default AwardPanel;
