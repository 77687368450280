import React from 'react';
import PropTypes from 'prop-types';

const Checkmark = ({
  className,
  fill
}) =>{
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 363.025 363.024">
      <path fill={fill} d="M147.957 258.935l-64.889-64.889 8.28-8.279 56.609 56.608 123.214-123.209 8.28 8.279z" />
    </svg>
  );
};

Checkmark.defaultProps = {
  fill: 'inherit',
  className: ''
};

Checkmark.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string
};

export default Checkmark;