import React from 'react';

class Accordion extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      collapsed: false
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(){
    this.setState((prevState) => {
      return {collapsed: !prevState.collapsed};
    });
  }

  render(){
    return(
      <div className="accordion">
        <div className="header">
          <div className="toggle" onClick={this.toggleCollapse}>{this.state.collapsed ? "＋" : "－"}</div>
          <h2>{this.props.title} <span className="count">{this.props.count ? `(${this.props.count})` : ""}</span></h2>
        </div>
        <div className={"content" + (this.state.collapsed ? " collapsed" : "")}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Accordion;