import React from 'react';

const UserListItem = (props) => {
  return (
    <tr>
      <td>{props.email}</td>
      <td>{props.role}</td>
      <td>{props.region}</td>
      <td>{props.access}</td>
      <td><a href="#">Edit</a></td>
      <td><a href="#">Delete</a></td>
      <td><a href="#">Resend Invite</a></td>
    </tr>
  );
}

export default UserListItem;