import { combineReducers } from 'redux';

const dummyReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const rootReducer = combineReducers({
  // individual reducers go here
  // remove dummyReducer once you start writing reducers
  dummyReducer
});

export default rootReducer;
