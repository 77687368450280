import React from 'react';
import PropTypes from 'prop-types';
import styles from './Maximize.module.scss';

const Maximize = ({
  onClick
}) =>{
  return (
    <button type="button" onClick={onClick} className={styles.Maximize}>
      <i className="fa fa-plus" />
    </button>
  );
};

Maximize.defaultProps = {
  onClick: ()=>{}
};

Maximize.propTypes = {
  onClick: PropTypes.func
};


export default Maximize;