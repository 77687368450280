import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import check from 'images/faq/check.svg';
import lock from 'images/faq/lock.png';
import faq_awards_screenshot_1 from 'images/faq/faq_awards_screenshot_1.png';
import faq_awards_screenshot_2 from 'images/faq/faq_awards_screenshot_2.png';
import faq_engraving_screenshot_1 from 'images/faq/faq_engraving_screenshot_1.png';
import faq_engraving_screenshot_2 from 'images/faq/faq_engraving_screenshot_2.png';
import btn_edit from 'images/btn_edit.png';
import btn_dropdown from 'images/btn_dropdown.png';
import btn_report from 'images/btn_report.png';
import btn_download from 'images/btn_download-faded.svg';


class Faq extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedGuide: 0
    }
    this.selectGuide = this.selectGuide.bind(this);
  }

  selectGuide(value) {
    this.setState({
      selectedGuide: value
    });
  }

  renderGlobalAwardGuide() {
    // Cheat sheet link
    // /pdfs/PS20__Global_Nomination_CheatSheet.pdf
    return (
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <a href="/pdfs/PS24_CheatSheet_Global_Nominations_v1.pdf" target="__blank">
            Download the Global Nomination Cheat Sheet
          </a>
          <div className="faq-section-header">
            Step I: Nominate a Partner
          </div>
          <div className="faq-section-text">
            Geo Leads are given access to the Global Award Nomination forms.
          </div>
          <ul>
            <li>To nominate a partner, select the award category from the Dashboard.</li>
            <li>Check <img src={check}/> will highlight once nomination information has been received</li>
            <li>Once you have submitted nominees for all of the Global Award categories, re&#8209;order your nominees from highest to lowest priority by dragging them into the order of preference.</li>
            <li>Use the <img src={lock} style={{width: "12px"}}/> toggle to lock in priority order to save your progress. <b>Priority order can be changed up until September 12.</b></li>
          </ul>
        </div>
        <div className="col-xs-12 col-md-6"><img src={faq_awards_screenshot_1} className="faq-screenshot" /></div>

        <div style={{clear: "both", paddingTop: "10px"}} />

        <div className="col-xs-12 col-md-6">
          <div className="faq-section-header">
            Step II: Winning Partner Information
          </div>
          <div className="faq-section-text">
            At the close of the nomination period, nominated partners will be sent to the executive committee for final voting.
          </div>
          <ul style={{marginBottom: '0px'}}>
            <li>If your partner is selected, you will receive a notification from the tool and the nominee will remain on your dashboard when you log into the tool (all other nominees will be suppressed).</li>
          </ul>
          <div className="faq-section-text">
            At this point, you will be able to enter information for your winning partner:
          </div>
          <ul>
            <li>Input Letter check will highlight once information has been submitted in the tool.</li>
            <li>Logo file for usage check will highlight once winner logo has been submitted in the tool.</li>
          </ul>
        </div>
        <div className="col-xs-12 col-md-6"><img src={faq_awards_screenshot_2} className="faq-screenshot" /></div>

        <div style={{clear: "both", paddingTop: "10px"}} />

        <div className="col-xs-12">
          <h4>FAQ</h4>
          <div className="faq-questions">
            <div className="faq-question">What if I cannot see the Global Award nomination area?</div>
            <div className="faq-answer">Requests for access can be sent to <a href="mailto:kimathie@cisco.com">kimathie@cisco.com</a>.</div>
          </div>
          <div className="faq-questions">
            <div className="faq-question">Do I need to supply a letter and logo file for all nominees?</div>
            <div className="faq-answer">No, you are only responsible for the input letter and logo file for winning partners.</div>
          </div>
          <div className="faq-questions">
            <div className="faq-question">What is the preferred file format for the logo?</div>
            <div className="faq-answer">Logos must be delivered as scalable, vector .eps files (.jpg or .png files will not be accepted in the tool).</div>
          </div>
        </div>
      </div>
    );
  }

  renderAwardEngravingGuide() {
    // Cheat sheet link
    // /pdfs/PS20__Engraver_CheatSheet.pdf
    return (
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <a href="/pdfs/PS24_CheatSheet_Engraver_v1.pdf" target="_blank">
            Download the Engraver Cheat Sheet
          </a>
          <div className="faq-section-header">
            Site Navigation
          </div>
          <div className="faq-section-text">
            Geo Leads are given access to the Global Award Nomination forms.
          </div>
          <ul>
            <li>Access award regions via their respective tile</li>
            <li>If you do not see your award region, contact <a href="mailto:kimathie@cisco.com">kimathie@cisco.com</a></li>
            <li>Access additional site functions via the sidebar</li>
          </ul>
          <div className="faq-section-header">
            Award Region Tile Detail
          </div>
          <ul>
            <li>Missing winners: awards missing the winning partner information</li>
            <li>Proof pending: award proofs being generated by Jaguar Design</li>
            <li>Approval pending: award proofs needing approval</li>
          </ul>
          <div className="faq-section-header">
            Step I: Entering Winning Partner Information
          </div>
          <ul>
            <li>Scroll down to the “Awards Missing Winners” section and select an award category</li>
            <li>Click on the “Enter Winner” button</li>
          </ul>
          <div className="faq-section-header">
            Step II: Etching Details
          </div>
          <ul>
            <li>Enter the winning partner exactly as you wish it to appear on the etched crystal award</li>
            {/* <li>Country/Area is optional, leave this field blank if it does not apply</li> */}
            <li>Order a printed certificate</li>
          </ul>
          <div className="faq-section-header">
            Step III: Shipping Information
          </div>
          <ul>
            <li>All crystal awards will be shipped following the conclusion of Cisco Partner Summit 2024, shipping information is required for each award</li>
          </ul>
          <div className="faq-section-header">
            Step IV: Proofing
          </div>
          <div className="faq-section-text">
            Once the etching and shipping data has been entered, Jaguar will create a proof (usually within 48 hrs). An email notification will be sent once your proof is ready for review.
          </div>
          <ul>
            <li>Download .pdf and .xls reports from the reports tab <img src={btn_report} style={{width: "20px"}}/> in the sidebar</li>
            <li>Edit <img src={btn_edit} style={{width: "15px"}}/> etching copy</li>
            <li>Download individual proof <img src={btn_download} style={{width: "15px"}}/></li>
            <li>Approve <img src={btn_dropdown} style={{width: "15px"}}/> award</li>
          </ul>
        </div>
        <div className="col-xs-12 col-md-6">
          <img src={faq_engraving_screenshot_1} className="faq-screenshot" />
          <img src={faq_engraving_screenshot_2} className="faq-screenshot" style={{marginTop: "10px"}} />
        </div>

        <div style={{clear: "both", paddingTop: "10px"}} />

        <div className="col-xs-12">
          <h4>FAQ</h4>
          <div className="faq-questions">
            <div className="faq-question">How do I add a new award I don't see as a part of the Category drop down menu?</div>
            <div className="faq-answer">Requests for additional awards can be sent to a <a href="mailto:kimathie@cisco.com">Cisco Admin</a> for approval.</div>
          </div>
          <div className="faq-questions">
            <div className="faq-question">I accidentally approved an award, how can I un-approve it?</div>
            <div className="faq-answer">You can edit approved awards until the tool closes (see the <a href="/milestones">Milestones</a> tab). After it's close, change orders must be approved by a <a href="mailto:kimathie@cisco.com">Cisco Admin</a>. If an award has already been etched, a new crystal must be ordered.</div>
          </div>
        </div>
      </div>
    );
  }

  renderGuide() {
    const { selectedGuide } = this.state;

    if (selectedGuide === 0) {
      return this.renderGlobalAwardGuide();
    }
    return this.renderAwardEngravingGuide();
  }

  render(){
    const { selectedGuide } = this.state;
    return(
      <>
        <div className="faq-selector">
          <h4 onClick={() => this.selectGuide(0)} className={selectedGuide === 0 ? "guide-selected" : ""}>Global Award Nominations</h4>
          <h4 onClick={() => this.selectGuide(1)} className={selectedGuide === 1 ? "guide-selected" : ""}>Award Engraving</h4>
        </div>
        <div className="page-box">
          {this.renderGuide()}
        </div>
      </>
    );
  }
}

export default Faq;
