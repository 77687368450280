import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Col from '@components/Global/Col';

const getCriterionClassName = (isIncomplete)=> classNames(
  'unapproved-count',
  {
    incomplete: isIncomplete
  }
)

const PanelCriterion = ({
  value,
  label,
  size
})=> (
  <Col el="span" className={`col-sm-${size}`}>
    <span className={getCriterionClassName(value > 0)}>
      {value}
    </span>
    <span className="label">
      {label}
    </span>
  </Col>
);

PanelCriterion.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default PanelCriterion;