/* eslint-disable camelcase */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, pipe } from 'lodash/fp';
import { map, pick, mapKeys, chunk } from 'lodash';
import VisibilityToggler from '@components/Dashboard/VisibilityToggler';
import SectionHeader from '@components/Dashboard/SectionHeader';
import TitleWithCount from '@components/Dashboard/TitleWithCount';
import Row from '@components/Global/Row';
import Col from '@components/Global/Col';
import AwardsPanel from '@components/Panels/AwardsPanel';


class TheaterSection extends Component {
  static propTypes = {
    toggleVisibility: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    theaters : PropTypes.arrayOf(
      PropTypes.shape({
        theater_name: PropTypes.string.isRequired,
        theater_awards_proof_url: PropTypes.string.isRequired,
        theater_engraving_status_report: PropTypes.shape({
          missing_winner:PropTypes.number.isRequired,
          proof_pending: PropTypes.number.isRequired,
          approval_pending: PropTypes.number.isRequired,
          approved: PropTypes.number.isRequired
        })
      })
    ).isRequired
  }

  createOptions(theater){
    return pipe(
      (report)=> pick(report, [ "missing_winner", "proof_pending", "approval_pending" ]),
      (statuses)=> mapKeys(statuses, (v, key) => key.replace('_', ' ')),
      (statuses)=> map(statuses, (value, label)=> ({label, value}) )
    )(theater.theater_engraving_status_report);
  }

  renderPanels(){
    const sortedTheaters = this.props.theaters.sort((a, b) => {
     let aSort = a.theater_name
     let bSort = b.theater_name
     if((aSort||"").indexOf(" Geo")>-1) {
       aSort = "____"
     }
     if((aSort||"").indexOf(" Theater")>-1) {
       aSort = "____"
     }
     if((bSort||"").indexOf(" Geo")>-1) {
       bSort = "____"
     }
     if((bSort||"").indexOf(" Theater")>-1) {
       bSort = "____"
     }
     return aSort.localeCompare(bSort)
     });
    const rows = chunk(sortedTheaters, 3);
    return map(rows, (row, i )=>(
      <Row key={i}>
        {
          map(row, theater => (
            <Col className="gutter-bottom" key={theater.theater_name} col-sm-12 col-md-4>
              <AwardsPanel
                href={theater.theater_awards_proof_url}
                options={this.createOptions(theater)}
                title={theater.theater_name}
                total={theater.theater_engraving_status_report.approved}
                bigTotal={theater.theater_engraving_status_report.approved + theater.theater_engraving_status_report.approval_pending + theater.theater_engraving_status_report.missing_winner + theater.theater_engraving_status_report.proof_pending}
                totalLabel='approved'
              />
            </Col>
          ))
        }
      </Row>
    ));
  }

  render() {
    const className = classNames(
      {
        'hide': !this.props.isVisible
      }
    );
    var totalAwards = this.props.theaters.reduce((accu, theater)=>{
      return accu += Object.values(theater.theater_engraving_status_report).reduce((a, b) => a + b);
    }, 0)
    var approved = this.props.theaters.reduce((accu, theater)=>{
      return accu += theater.theater_engraving_status_report.approved
    }, 0)
    return (
      <section>
        <SectionHeader
          title={()=> (
            <TitleWithCount
              title={this.props.title}
              count={totalAwards+" Total Awards - "+approved+" Approved"}
            />
          )}
          onClick={this.props.toggleVisibility}
          isVisible={this.props.isVisible}
        />
        <div className={className}>
          {this.renderPanels()}
        </div>
      </section>
    );
  }
}

const enhance = compose(
  VisibilityToggler
);

export default enhance(TheaterSection);
