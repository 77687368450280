import React from 'react';
import PropTypes from 'prop-types';
import { startsWith, pickBy, keys, omit } from 'lodash';
import classNames from 'classnames';

const getColKeys = props => keys( pickBy(props, (v, k)=> startsWith(k, 'col')) );

const Col = ({
  el,
  children,
  className,
  ...rest
}) =>{
  const Element = el;
  const colKeys = getColKeys(rest);
  const _className = classNames(
    'col',
    colKeys,
    className
  )

  return (
    <Element
      className={_className}
      {...omit(rest, colKeys)}
    >
      {children}
    </Element>
  );
};

Col.defaultProps ={
  el: 'div',
  className: ''
}

Col.propTypes ={
  el: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Col;