import React, { Component } from 'react';
import Button from './Button'

class Login extends Component {
  constructor(props){
    super(props)
    this.state = {
      altLogin: false
    }
    this.toggleAltLogin = this.toggleAltLogin.bind(this);
  }

  toggleAltLogin(){
    this.setState((prevState)=>{
      return({altLogin: !prevState.altLogin})
    });
  }

  render(){
    if(this.state.altLogin){
      return(
        <div className="login-container">
          <div className="login-panel">
            <div className="cisco-logo"></div>
            <h1>Partner Summit 2020 Awards Tool</h1>
            <p>Log in to your account</p>
            <input type="text" placeholder="Username or email" />
            <div className="small-link">Forgot username?</div>
            <Button type="primary" label="Next" handleClick={this.props.loginAction} />
            <div className="not-associated">Create a new account</div>
          </div>
        </div>
      );
    }
    return(
      <div className="login-container">
        <div className="login-panel">
          <div className="cisco-logo"></div>
          <h1>Partner Summit 2020 Awards Tool</h1>
          <h2>Part of Cisco?</h2>
          <p>Cisco uses your network username and password to login to the Awards Tool.</p>
          <Button type="primary" label="Continue" handleClick={this.toggleAltLogin} />
          <div className="not-associated">Not a part of Cisco?</div>
        </div>
      </div>
    );
  }
}

export default Login;
