import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from './Loader';
import styles from './CenteredLoader.module.scss';

const CenteredLoader = ({
  className
}) =>{
  return (
    <div className={classNames(styles.CenteredLoader, className)}>
      <Loader />
    </div>
  );
};

CenteredLoader.defaultProps = {
  className: ''
};
CenteredLoader.propTypes = {
  className: PropTypes.string
};

export default CenteredLoader;