import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from '@components/Global/Row';
import Col from '@components/Global/Col';
import Checkmark from '@components/Icons/Checkmark';

const getClassName = (isChecked, partial = false)=> classNames(
  'checkmark', { checked: isChecked, partial: partial }
);

const GlobalAwardPanel = ({
  letter,
  linkPath,
  logo,
  nomination,
  nomsComplete,
  title,
  winner
}) => {
  return (
    <a className="global-award-panel" href={linkPath}>
      <h3>{title}</h3>
      <div className="progress-panel container">
        <h4>nomination progress</h4>
        <Row className="text-center no-gutters">
          <Col col-sm-3>
            <div className={getClassName(nomination, (nomination && !nomsComplete))}>
              <Checkmark />
            </div>
            <div className="label">
              nomination received
            </div>
          </Col>
          <Col col-sm-3>
            <div className={getClassName(winner)}>
              <Checkmark />
            </div>
            <div className="label">
              winner announced
            </div>
          </Col>
          <Col col-sm-3>
            <div className={getClassName(letter)}>
              <Checkmark />
            </div>
            <div className="label">
              partner winner info published
            </div>
          </Col>
          <Col col-sm-3>
            <div className={getClassName(logo)}>
              <Checkmark />
            </div>
            <div className="label">
              logo file received
            </div>
          </Col>
        </Row>
      </div>
    </a>
  );
};

GlobalAwardPanel.propTypes = {
  nomination: PropTypes.bool.isRequired,
  nomsComplete: PropTypes.bool.isRequired,
  winner: PropTypes.bool.isRequired,
  letter: PropTypes.bool.isRequired,
  logo: PropTypes.bool.isRequired,
  linkPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default GlobalAwardPanel;
