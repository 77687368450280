import React from 'react'

const Nav = (props) => {
  return(
      <nav>
        <div className="logo"></div>
        <ul>
          <li>
            <a className="nav-link" href="#">
              <div className="nav-img dashboard selected"></div>
              <div className="nav-text">Dashboard</div>
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              <div className="nav-img milestones"></div>
              <div className="nav-text">Milestones</div>
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              <div className="nav-img Reports"></div>
              <div className="nav-text">Reports</div>
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              <div className="nav-img users"></div>
              <div className="nav-text">Manage Users</div>
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              <div className="nav-img inventory"></div>
              <div className="nav-text">Inventory</div>
            </a>
          </li>
          <li>
            <a className="nav-link" href="#">
              <div className="nav-img faq"></div>
              <div className="nav-text">Tutorial / FAQ</div>
            </a>
          </li>
        </ul>
        <div className="divider"></div>
        <a className="nav-link" href="#">
          <div className="nav-img logout"></div>
          <div className="nav-text">Logout</div>
        </a>
      </nav>
    );
}

export default Nav;