import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './TitleWithCount.module.scss';

const TitleWithCount = ({
  title,
  count
})=>(
  <Fragment>
    {title}
    { !! count && (
      <span className={styles.count}>
        ({count})
      </span>
    )}
  </Fragment>
);

TitleWithCount.defaultProps = {
  count: "0"
};

TitleWithCount.propTypes = {
  count: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default TitleWithCount;