/* eslint-disable react/prefer-stateless-function */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {compose} from 'lodash/fp';
import { chunk, map, isEmpty } from 'lodash';
import ApiFetcher from '@components/Dashboard/ApiFetcher';
import VisibilityToggler from '@components/Dashboard/VisibilityToggler';
import SectionHeader from '@components/Dashboard/SectionHeader';
import GlobalAwardPanel from '@components/GlobalAwardPanel';
import Row from '@components//Global/Row';
import Col from '@components/Global/Col';

class GlobalAwardNominations extends Component {
  static propTypes = {
    toggleVisibility: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      global_award_categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          nominations: PropTypes.array.isRequired,
          nominations_complete: PropTypes.bool.isRequired,
          select_winner_url: PropTypes.string.isRequired,
          winner_input_logo_image_url: PropTypes.string.isRequired,
          winner_input_logo_image_filename: PropTypes.string.isRequired,
          winner_input: PropTypes.object.isRequired,
          winner: PropTypes.object.isRequired
        })
      )
    }).isRequired
  }

  get categories(){
    const geo_region_awards = this.props.data.global_award_categories.filter(gac => gac.region_id !== "").sort((a, b) => a.name.localeCompare(b.name))
    const excellence_awards = this.props.data.global_award_categories.filter(gac => gac.region_id === "").sort((a, b) => a.name.localeCompare(b.name))
    return geo_region_awards.concat(excellence_awards);
  }

  renderGlobalAwards(){
    console.log(this.categories);
    const rows = chunk(this.categories, 3);
    return map(rows, (row, i) => (
      <Row key={i}>
        {
          map(row, award => (
            <Col className="gutter-bottom" col-sm-12 col-md-4 key={award.id}>
              <GlobalAwardPanel
                nomination={!!award.nominations.length}
                nomsComplete={award.nominations_complete}
                winner={!isEmpty(award.winner)}
                letter={!isEmpty(award.winner_input) && award.winner_input.draft === false}
                logo={!!award.winner_input_logo_image_filename}
                linkPath={award.select_winner_url}
                title={award.name}
              />
            </Col>
          ))
        }
      </Row>
    ));
  }

  render() {
    const className = classNames(
      {
        'hide': !this.props.isVisible
      }
    );
    return (
      <section>
        <SectionHeader
          title="Global Award Nominations"
          onClick={this.props.toggleVisibility}
          isVisible={this.props.isVisible}
        />
        <br />
        <div className={className}>
          <div className="row">
            <a className="btn primary float-right floating-menu-button" href="/manage/global_award_categories/new">Add an Award Category</a>
            <br />
          </div>
          {this.renderGlobalAwards()}
        </div>
      </section>
    );
  }
}

const enhance = compose(
  VisibilityToggler,
  ApiFetcher
);

export default enhance(GlobalAwardNominations);
