import React from 'react';
import PropTypes from 'prop-types';
import GlobalAwardNominations from './Sections/GlobalAwardNominations';
import GlobalAwards from './Sections/GlobalAwards';

const Dashboard = (props) =>{
  return (
    <main>
      <GlobalAwardNominations />
      <GlobalAwards />
    </main>
  );
};

Dashboard.propTypes ={};

export default Dashboard;