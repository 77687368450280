import React from 'react';
import PropTypes from 'prop-types';
import image from 'images/loader.gif'

const Loader = (props) =>{
  return (
    <img alt="loader" src={image} />
  );
};

Loader.propTypes ={};

export default Loader;