import React, { Component } from 'react';
import Button from './Button';
import UserListItem from './UserListItem';
import wrapper from './Wrapper';

class UserManagement extends Component {
  constructor(props){
    super(props);
    console.log(props);
  }

  render(){
    return(
      <div className="user-management">
        <Button type={"primary"} label={"Add a User +"} handleClick={()=>{this.props.toggleModal("NewUserForm")}} />
        <h2>Manage Users</h2>
        <div className="page-box">
          <table>
            <tbody>
              <tr>
                <th>Email</th>
                <th>Role</th>
                <th>Region</th>
                <th>Access to Nomination Tool</th>
              </tr>
              {this.props.data.map((item, key)=>{
                return(
                  <UserListItem
                    email={item.email}
                    key={key}
                    role={item.role}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default wrapper(UserManagement);