import React from 'react';
import Button from './Button';

const SampleForm = (props) => {
  return(
    <div className="form-component">
      <div className="form-header">
        <div className="button-container">
          <Button type="secondary" label="Cancel" handleClick={props.error} />
          <Button type="primary" label="Save" handleClick={props.complete} />
        </div>
        <h2>Sample Form 1</h2>
      </div>
      <form>
        <label>First name:</label>
        <input type="text" name="firstname" /><br />
        <div className="form-subheader">
          <h3>Shipping</h3>
          <p>All <strong>Global</strong> awards will ship to partner summit for onsite distrobution. If you are outside the region or event area, please follow the actual instructions and not this placeholder text.</p>
        </div>
        <label>Last name:</label>
        <input type="text" name="lastname" />
      </form>
      <div className="button-container">
        <Button type="secondary" label="Cancel" handleClick={props.error} />
        <Button type="primary" label="Save" handleClick={props.complete} />
      </div>
    </div>
  );
}

export default SampleForm;