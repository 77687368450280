import React from 'react';
import Button from './Button';
import FormIndex from './FormIndex'
import FocusLock from "react-focus-lock";

const Modal = (props) => {

  const Form = FormIndex[props.contentType] || function(){return("")};

  return(
    <div className={"custom-modal" + (!props.active ? " suppressed" : "")} >
        <div className="backdrop" onClick={function(e){props.toggleModal()}}></div>
        <div className="window">
          <FocusLock disabled={!props.active} persistentFocus={true}>
            <Form complete={props.toggleModal} error={props.toggleModal} />
          </FocusLock>
        </div>
    </div>
  );
}

export default Modal;