import React from 'react';

const ReportList = (props) => {
  return(
    <div className="report-list">
      <h3>{props.title}</h3>
      <table>
        <tbody>
          <tr>
           <td colSpan="0">Name</td>
           <td>Count</td>
           <td><a href="#">pdf link</a></td>
           <td><a href="#">xls link</a></td>
          </tr>
          <tr>
           <td colSpan="0">Name</td>
           <td>Count</td>
           <td><a href="#">pdf link</a></td>
           <td><a href="#">xls link</a></td>
          </tr>
          <tr>
           <td colSpan="0">Name</td>
           <td>Count</td>
           <td><a href="#">pdf link</a></td>
           <td><a href="#">xls link</a></td>
          </tr>
          <tr>
           <td colSpan="0">Name</td>
           <td>Count</td>
           <td><a href="#">pdf link</a></td>
           <td><a href="#">xls link</a></td>
          </tr>
          <tr>
           <td colSpan="0">Name</td>
           <td>Count</td>
           <td><a href="#">pdf link</a></td>
           <td><a href="#">xls link</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ReportList;