/* eslint-disable camelcase */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { compose} from 'lodash/fp';
import { map } from 'lodash';
import ApiFetcher from '@components/Dashboard/ApiFetcher';
import TheaterSection from './TheaterSection';


class TheaterAwards extends Component {
  static propTypes = {
    data: PropTypes.shape({
      theater_awards_engraving_status_reports: PropTypes.objectOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            theater_name: PropTypes.string.isRequired,
            theater_awards_proof_url: PropTypes.string.isRequired,
            theater_engraving_status_report: PropTypes.shape({
              missing_winner:PropTypes.number.isRequired,
              proof_pending: PropTypes.number.isRequired,
              approval_pending: PropTypes.number.isRequired,
              approved: PropTypes.number.isRequired
            })
          })
        )
      )
    }).isRequired
  }

  get sections(){
    return this.props.data.theater_awards_engraving_status_reports;
  }

  render() {
    return (
      map(this.sections, (theaters, title) => (
        <TheaterSection
          key={title}
          title={title}
          theaters={theaters}
        />
      ))
    );
  }
}

const enhance = compose(
  ApiFetcher
);

export default enhance(TheaterAwards);