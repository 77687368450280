import React from 'react';
import PropTypes from 'prop-types';
import styles from './Minimize.module.scss';

const Minimize = ({
  onClick
}) =>{
  return (
    <button type="button" onClick={onClick} className={styles.Minimize}>
      <i className="fa fa-minus" />
    </button>
  );
};

Minimize.defaultProps = {
  onClick: ()=>{}
};

Minimize.propTypes = {
  onClick: PropTypes.func
};


export default Minimize;