import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Row = ({
  el,
  children,
  className,
  collapse,
  ...rest
}) =>{
  const Element = el;
  const _className = classNames(
    'row',
    {
      'collapse': collapse
    },
    className
  )

  return (
    <Element
      className={_className}
      {...rest}
    >
      {children}
    </Element>
  );
};

Row.defaultProps ={
  el: 'div',
  className: '',
  collapse: false
}

Row.propTypes = {
  el: PropTypes.string,
  className: PropTypes.string,
  collapse: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Row;