/* eslint-disable react/sort-comp */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import CenteredLoader from '@components/Loaders/CenteredLoader';

export default function ApiFetcherHOC (WrappedComponent) {
  class ApiFetcher extends Component {
    static displayName = `ApiFetcher(${WrappedComponent.name || 'Component'})`
    static propTypes = {
      endpoint: PropTypes.string.isRequired
    }

    state = {
      isFetching: false,
      hasError: false,
      response: null
    }

    get isFetching(){
      return this.state.isFetching;
    }

    set isFetching(isFetching){
      this.setState({isFetching})
    }

    get response(){
      return this.state.response;
    }

    set response(response){
      this.setState({response});
    }

    get hasError(){
      return this.state.hasError;
    }

    set hasError(hasError){
      this.setState({hasError})
    }

    async componentDidMount(){
      try {
        this.isFetching = true;
        const response = await axios.get(this.props.endpoint);
        this.response = response;
      } catch (error) {
        this.hasError = true;
      } finally {
        this.isFetching = false;
      }
    }

    render() {
      if(this.hasError){
        return null
      }

      return (
        this.isFetching || ! this.response
          ? <CenteredLoader />
          : (
            <WrappedComponent
              data={this.response.data}
              {...this.props}
            />
          )
      );
    }
  }

  return ApiFetcher
}

