/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CircularProgressbar } from 'react-circular-progressbar';

const getClassName = (percentComplete)=> classNames({
  complete: percentComplete === 100,
  negative: percentComplete === Infinity
});

const getPercentComplete = (count, total)=>{
  // Divisor (total) must not be 0
  if(total){
    return (count / total) * 100
  }
  return 0;
}

const DialMeter = ({
  count,
  label,
  show_total,
  title,
  total
}) => {
  const percentComplete = getPercentComplete(count, total);
  const totalDisplay = show_total ? (<span className="total">({total})</span>) : "";

  return (
    <div className="dial-meter">
      <h4>{title} {totalDisplay}</h4>
      <CircularProgressbar
        value={percentComplete}
        text={`${total-count}`}
        strokeWidth={20}
        classForPercentage={()=> getClassName(percentComplete)}
      />
      <aside>{label}</aside>
    </div>
  );
}

DialMeter.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  show_total: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired
};

export default DialMeter;
