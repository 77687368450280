import React, { Component } from 'react';
import { Provider } from 'react-redux';
import configureStore from '../store/store';
import Modal from './Modal.js';

function wrapper(WrappedComponent) {
  return class Wrapper extends Component {

    constructor(props){
      super(props);
      this.store = configureStore(props.data);
      this.toggleModal = this.toggleModal.bind(this);
      this.state = {
        modalActive: false,
        modalContentType: ""
      }
    }

    toggleModal(contentType){
      this.setState(function(prevState){
        if(!contentType){
          return({modalActive: !prevState.modalActive, modalContentType: prevState.modalContentType});
        }
        return({modalActive: !prevState.modalActive, modalContentType: contentType});
      });
    }

    render(){
      return(
        <Provider store={this.store} >
          <div className="page-content">
            <Modal
              active={this.state.modalActive}
              toggleModal={this.toggleModal}
              contentType={this.state.modalContentType}
            />
            <div className="container">
              <div className="header">
                <h1>Partner Summit 2020 Awards Tool</h1>
              </div>
              <WrappedComponent toggleModal={this.toggleModal} {...this.props} />
            </div>
          </div>
        </Provider>
      );
    }
  }
}

export default wrapper;
