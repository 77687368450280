import React from 'react';

const EngraverPanel = (props) => {
  return(
    <div className="engraver-panel">
      <div className="top">
        <label>{props.title}</label>
        <div className="region">{props.region}</div>
        <div className="engraver-preview">
        </div>
        <label>Last updated on {props.updatedAt} by {props.user}</label>
      </div>
      <div className="bottom">
          <div className="third">
            <div className="control edit" onClick={props.edit}></div>
          </div>
          <div className="third">
            <div className="control attach" onClick={props.attach}></div>
          </div>
          <div className="third">
            <div className="control dropdown" onClick={props.dropdown}></div>
          </div>
      </div>
    </div>
  );
}

export default EngraverPanel;