import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default function VisibilityTogglerHOC (WrappedComponent) {
  class VisibilityToggler extends Component {
    static displayName = `VisibilityToggler(${WrappedComponent.name || 'Component'})`
    static propTypes = {}
  
    constructor(props){
      super(props);
      this.toggleVisibility = this.toggleVisibility.bind(this)
    }
    
    state = {
      isVisible: true
    }

    toggleVisibility(){
      this.setState({isVisible: !this.state.isVisible})
    }

    render() {
      return (
        <WrappedComponent
          isVisible={this.state.isVisible}
          toggleVisibility={this.toggleVisibility}
          {...this.props}
        />
      ); 
    }
  }

  return VisibilityToggler
}

