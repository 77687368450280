import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isString } from 'lodash';
import Row from '../../Global/Row';
import Col from '../../Global/Col';
import Minimize from '../../Buttons/Minimize';
import Maximize from '../../Buttons/Maximize';
import styles from './SectionHeader.module.scss';

const SectionHeader = ({
  title,
  onClick,
  isVisible
}) =>{
  const Title = isString(title) ? ()=> title : title;
  return (
    <Row el="header" className={classNames(styles.SectionHeader)}>
      <Col el="h2" col-sm-11>
        <Title />
      </Col>
      <Col col-sm-1>
        { 
          isVisible
            ? <Minimize onClick={onClick} />
            : <Maximize onClick={onClick} />
        }
      </Col>
    </Row>
  );
};

SectionHeader.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func
  ]).isRequired
};

export default SectionHeader;